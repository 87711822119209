import { combineReducers } from "redux";
import userReducer from "./UserReducer";
import collectionsReducer from "./CollectionsReducer";
import collectionReducer from "./CollectionReducer";
import modalReducer from "./ModalReducer";
import focusReducer from "./FocusReducer";
import documentReducer from "./DocumentReducer";

const rootReducer = combineReducers({
  user: userReducer,
  focus: focusReducer,
  modals: modalReducer,
  document: documentReducer,
  collections: collectionsReducer,
  collection: collectionReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
