import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Divider,
  Icon,
  Menu as SementicMenu,
  Input,
  Loader,
  Segment,
  Transition,
  Grid,
  Container,
  List,
} from "semantic-ui-react";
import { error, success } from "../global/Popup";
import CollectionService from "../../services/CollectionService";
import { useSelector } from "react-redux";
import { RootState } from "../../store/reducers/RootReducer";

const SideMenu = ({ refreshCollections }: { refreshCollections: any }) => {
  const animationStyle = "fade";
  const animationTimeInMilliseconds = 600;

  const location = useLocation();
  const path = location.pathname;

  const identifierMatch = path.match(/\/collection\/([^/]+)/);
  var activeItem = identifierMatch ? identifierMatch[1] : null;
  const queryParams = new URLSearchParams(location.search);
  const filterTag = queryParams.get("tag") || "";

  if (path === "/") {
    activeItem = "home";
  }

  const [collectionAddText, setCollectionAddText] = useState("");
  const collections = useSelector((state: RootState) => state.collections);
  const collection = useSelector((state: RootState) => state.collection);
  const [previousActiveItem, setPreviousActiveItem] = useState(activeItem);

  const [collapsedSubMenu, setCollapsedSubMenu] = useState(true);

  useEffect(() => {
    if (previousActiveItem !== activeItem && filterTag !== "") {
      setCollapsedSubMenu((prev) => !prev);
    } else if (filterTag === "") {
      setCollapsedSubMenu(true);
    }
  }, [previousActiveItem, activeItem]);

  const handleCollectionAddTextChange = (_: any, data: any) => {
    setCollectionAddText(data.value);
  };

  const handleSuccess = (_: any) => {
    setCollectionAddText("");
    success("New Collection added.");
    refreshCollections();
  };

  const handleFailure = (message: String) => {
    error(message);
  };

  const handleCollectionAddKeyPress = (event: any) => {
    if (event.key === "Enter") {
      if (collectionAddText) {
        new CollectionService().create(
          collectionAddText,
          "",
          true,
          handleSuccess,
          handleFailure,
        );
      }
    }
  };

  const showSubMenu = (activeItem: any, collection: any) => {
    return activeItem === collection.identifier;
  };

  const handleContactUs = () => {
    const recipient = "hello@satellax.com";
    const subject = "Hello!";

    const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(subject)}`;
    window.location.href = mailtoLink;
  };

  const handleMenuItemClick = () => {
    if (previousActiveItem !== activeItem && filterTag !== "") {
      setPreviousActiveItem(collection.data.identifier);
    } else if (filterTag === "") {
      setCollapsedSubMenu((prevState) => !prevState);
    }
  };

  return (
    <Segment style={{ borderRadius: "12px", padding: "15px" }}>
      <SementicMenu
        id="side-menu"
        size="huge"
        vertical
        style={{ height: "10%", border: "None", boxShadow: "None" }}
      >
        <div style={{ padding: "0px 4px" }}>
          <SementicMenu.Item
            name="home"
            as={Link}
            to="/"
            active={activeItem === "home"}
            style={{ borderRadius: "12px", padding: "15px", margin: "3px 0px" }}
          >
            <Icon name="home" /> Home
          </SementicMenu.Item>
          {/* <SementicMenu.Item
            name="news"
            as={Link}
            to="/news"
            active={activeItem === "news"}
            style={{ borderRadius: "12px", padding: "15px", margin: "5px 0px" }}
          >
            <Icon name="newspaper" /> News
          </SementicMenu.Item> */}
          <Divider horizontal>Collections</Divider>
        </div>

        {!collections.data && (
          <Loader active content="Loading" inline="centered" />
        )}

        {collections.data && (
          <>
            <SementicMenu.Item>
              <Input
                transparent
                id="collection-add"
                value={collectionAddText}
                onChange={handleCollectionAddTextChange}
                placeholder="Add Collection..."
                onKeyPress={handleCollectionAddKeyPress}
              />
            </SementicMenu.Item>
            <div
              className="no-scroll"
              style={{
                overflowY: "scroll",
                maxHeight: "56vh",
                // overflowX: "hidden",
              }}
            >
              {collections.data.map((it: any) => (
                <div key={it.identifier}>
                  <div
                    key={it.identifier}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "0px",
                      padding: "0px 4px",
                    }}
                  >
                    <SementicMenu.Item
                      name={it.identifier}
                      as={Link}
                      to={"/collection/" + it.identifier}
                      key={it.identifier}
                      active={activeItem === it.identifier}
                      onClick={() => handleMenuItemClick()}
                      style={{
                        borderRadius: "12px",
                        padding: "15px",
                        margin: "3px 0px",
                        flex: 1,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {it.private === true ? (
                        <>
                          <Icon name="eye slash" /> {it.title}
                        </>
                      ) : (
                        <>{it.title}</>
                      )}
                    </SementicMenu.Item>
                  </div>
                  {it.tags.length > 0 && collapsedSubMenu === true ? (
                    <Transition
                      duration={animationTimeInMilliseconds}
                      animation={animationStyle}
                      visible={showSubMenu(activeItem, it)}
                    >
                      {showSubMenu(activeItem, it) ? (
                        <SementicMenu
                          size="huge"
                          vertical
                          style={{
                            border: "none",
                            boxShadow: "none",
                            paddingLeft: "4px",
                          }}
                        >
                          {it.tags.map((tag: string) => (
                            <SementicMenu.Item
                              key={tag}
                              style={{
                                padding: "15px 15px 15px 26px",
                                marginRight: "4px",
                                borderRadius: "12px",
                              }}
                              name={tag}
                              as={Link}
                              to={
                                "/collection/" + it.identifier + "?tag=" + tag
                              }
                              active={filterTag === tag}
                            ></SementicMenu.Item>
                          ))}
                        </SementicMenu>
                      ) : (
                        <></>
                      )}
                    </Transition>
                  ) : (
                    <></>
                  )}
                </div>
              ))}
            </div>
          </>
        )}
      </SementicMenu>
      <Divider />
      <Container style={{ padding: "0 18px" }}>
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column>
              <List link>
                <List.Item as="a" href="/about">
                  About
                </List.Item>
                <List.Item as="a" onClick={handleContactUs}>
                  Contact Us
                </List.Item>{" "}
              </List>
            </Grid.Column>
            <Grid.Column>
              <List link>
                <List.Item as="a" href="/privacy-policy">
                  Privacy Policy
                </List.Item>
                <List.Item as="a" href="/terms-of-service">
                  Terms of Service
                </List.Item>
              </List>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  );
};

export default SideMenu;
