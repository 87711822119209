import { protectedApi } from './BaseApi'

const ENDPOINT = "collection"

export default class CollectionService {
    create(title: String, description: String, _private: boolean, onSuccess: any, onFailure: any) {
        protectedApi
            .post(ENDPOINT, { title, description, ['private']: _private })
            .then(response => {
                if (response.data.header.status == "SUCCESS") {
                    onSuccess(response.data.collection);
                } else {
                    onFailure(response.data.message);
                }
            })
            .catch(_error => {
                onFailure("Failed to create Collection.")
            });
    }
    
    retrieveAll(onSuccess: any, onFailure: any) {
        protectedApi
            .get(ENDPOINT)
            .then(response => {
                if (response.data.header.status == "SUCCESS") {
                    onSuccess(response.data.collections);
                } else {
                    onFailure(response.data.message);
                }
            })
            .catch(_error => {
                onFailure("Failed to retrieve Collections.")
            });
    }

    retrieve(identifier: String, onSuccess: any, onFailure: any) {
        protectedApi
            .get(ENDPOINT + "/" + identifier)
            .then( response => {
                if (response.data.header.status == "SUCCESS") {
                    onSuccess(response.data.collection);
                } else {
                    onFailure(response.data.message);
                }
            })
            .catch(_error => {
                onFailure("Failed to retrieve Collection.");
            });
    }

    update(identifier: String, title: String, description: String, _private: boolean, onSuccess: any, onFailure: any) {
        protectedApi
            .put(ENDPOINT + "/" + identifier, {title, description, ['private']: _private})
            .then( response => {
                if (response.data.header.status == "SUCCESS") {
                    onSuccess(response.data.collection);
                } else {
                    onFailure(response.data.message);
                }
            })
            .catch(_error => {
                onFailure("Failed to update Collection.");
            });
    }

    delete(identifier: String, onSuccess: any, onFailure: any) {
        protectedApi
            .delete(ENDPOINT + "/" + identifier)
            .then( response => {
                if (response.data.header.status == "SUCCESS") {
                    onSuccess(response.data.collection);
                } else {
                    onFailure(response.data.message);
                }
            })
            .catch(_error => {
                onFailure("Failed to delete Collection.");
            });
    }
}
