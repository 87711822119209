import { protectedApi, authApi } from "./BaseApi";
import Cookies from "js-cookie";

const USER_ENDPOINT = "user";
const LOGIN_ENDPOINT = "login";
const SIGNUP_ENDPINT = "sign-up";
const REGISTER_ENDPOINT = "register";
const PROFILE_ENDPOINT = "me";
const COOKIE_SETTINGS = "cookieSettings";
const TOKEN = "token";

export default class UserService {
  retrieve(onSuccess: any, onFailure: any) {
    protectedApi
      .get(USER_ENDPOINT + "/" + PROFILE_ENDPOINT)
      .then((response) => {
        if (response.data.header.status === "SUCCESS") {
          onSuccess(response.data.user);
        } else {
          onFailure(response.data.message);
        }
      })
      .catch((_error) => {
        onFailure("Failed to retrieve User information");
      });
  }

  login(
    emailAddress: String,
    password: String,
    onSuccess: any,
    onFailure: any,
  ) {
    authApi
      .post(LOGIN_ENDPOINT, { emailAddress, password })
      .then((response) => {
        if (response.data.header.status === "SUCCESS") {
          onSuccess(response.data.token);
        } else {
          onFailure(response.data.message);
        }
      })
      .catch((_error) => {
        onFailure("Failed to retrieve User information");
      });
  }

  signup(
    emailAddress: String,
    recaptchaToken: String,
    onSuccess: any,
    onFailure: any,
  ) {
    authApi
      .post(SIGNUP_ENDPINT, { emailAddress, recaptchaToken })
      .then((response) => {
        if (response.data.header.status === "SUCCESS") {
          onSuccess(response.data.token);
        } else {
          onFailure(response.data.message);
        }
      })
      .catch((_error) => {
        onFailure("Failed to retrieve User information");
      });
  }

  logOut() {
    Cookies.remove(TOKEN, {
      path: "/",
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
    });
  }

  isLoggedIn() {
    var token = Cookies.get(TOKEN);
    if (token) return true;
    else return false;
  }

  setSelectedCookieSettings(settings: any) {
    localStorage.setItem(COOKIE_SETTINGS, JSON.stringify(settings));
  }

  retrieveSelectedCookieSettings() {
    var settings = localStorage.getItem(COOKIE_SETTINGS);
    if (settings != null) {
      return JSON.parse(settings);
    }
    return null;
  }

  register(
    firstName: string,
    lastName: string,
    password: string,
    confirmPassword: string,
    token: string,
    identifier: string,
    termsOfServiceChecked: boolean,
    onSuccess: any,
    onFailure: any,
  ) {
    authApi
      .post(REGISTER_ENDPOINT, {
        firstName,
        lastName,
        password,
        confirmPassword,
        token,
        identifier,
        termsOfServiceChecked,
      })
      .then((response) => {
        if (response.data.header.status === "SUCCESS") {
          onSuccess(response.data);
        } else {
          onFailure(response.data.message);
        }
      })
      .catch((_error) => {
        onFailure("Failed to register.");
      });
  }
}
