export const TERMS_OF_SERVICE = `SATELLAX TERMS OF SERVICE

1. Introduction

Welcome to Satellax! These terms and conditions govern your use of the Satellax platform, owned by Satellax PTY ("Satellax," "we," "us," or "our").

2. Acceptance of Terms

By accessing or using Satellax, you agree to abide by these terms and conditions. If you do not agree with any part of these terms, you may not access or use our platform.

3. User Accounts

a. You may create an account on Satellax, but you must comply with any age restrictions imposed by applicable laws in your jurisdiction.

b. You are responsible for maintaining the security of your account credentials.

4. Premium Tiers

a. Satellax offers premium tiers with additional features and benefits. Users must pay a fee to access premium features.

b. The specific features available in each premium tier may vary, and Satellax reserves the right to modify the features offered in each tier at any time.

c. Failure to pay the required fees for the premium tier may result in suspension or termination of your account.

5. Data Privacy

a. We respect your privacy and handle your personal data in accordance with our Privacy Policy.

b. By using Satellax, you consent to receiving marketing emails from us. You can opt out of marketing communications at any time.

6. User Responsibilities

a. Users must report any illegal behavior or violations of our content guidelines, including hate speech, pornography, or explicit content, they encounter on the platform.

b. Satellax reserves the right to take down any content that violates our guidelines or applicable laws, including hate speech, pornography, or explicit content.

c. Users must not engage in any activity that could harm the integrity or security of the Satellax platform, including attempting to gain unauthorized access to accounts or systems.

d. Users are responsible for maintaining the confidentiality of their account credentials and for all activities that occur under their account.

e. Users must respect the intellectual property rights of others and not infringe upon copyrights, trademarks, or other proprietary rights.

f. Users must not impersonate others or misrepresent their identity on the Satellax platform.

g. Users are encouraged to engage with other users respectfully and constructively, avoiding harassment, bullying, or any form of abusive behavior.

h. Users must comply with all applicable laws and regulations while using Satellax.

i. Users are responsible for ensuring that any content they share on the platform, including text, images, or links, complies with our guidelines and does not violate the rights of others or contain malicious software or malware.

j. Users must promptly update their account information if it changes and inform Satellax of any unauthorized access to their account.

7. Dispute Resolution

a. Any disputes arising between users and Satellax shall be resolved through arbitration/mediation in accordance with the laws of the jurisdiction specified in the governing law section below.

8. Indemnification

a. Users agree to indemnify and hold Satellax harmless from any claims, damages, or liabilities arising from their use of the platform.

9. Severability

a. If any portion of these terms and conditions is found to be unenforceable, the remaining provisions will still apply.

10. Waiver

a. Failure to enforce any provision of these terms and conditions does not constitute a waiver of that provision.

11. Entire Agreement

a. These terms and conditions constitute the entire agreement between users and Satellax, superseding any prior agreements or understandings.

12. Survival

a. The provisions of these terms and conditions that by their nature should survive termination of the agreement shall survive.

13. Third-Party Rights

a. No third party shall have any rights or obligations under these terms and conditions.

14. Governing Law

a. These terms and conditions are governed by the laws of the jurisdiction specified in the governing law section below, without regard to its conflict of laws provisions.

15. Changes to Terms

a. We may revise these terms and conditions at any time without prior notice. By continuing to use Satellax after any revisions, you agree to be bound by the updated terms.

16. Advertising

a. Satellax may display advertisements on the platform. By using Satellax, you agree to the display of advertisements.

17. Contact Us

If you have any questions or concerns about these terms and conditions, please contact us at hello@satellax.
`;

export const PRIVACY_POLICY = `SATELLAX PRIVACY POLICY
This Privacy Policy describes how Satellax PTY collects, uses, and discloses personal information we receive from users of our website Satellax.

Information Collection and Use
While using our website, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to, your name, email address, and interactions with collections, documents, and posts ("Personal Information").

Log Data
Like many site operators, we collect information that your browser sends whenever you visit our website ("Log Data"). This Log Data may include information such as your computer's Internet Protocol ("IP") address, browser type, browser version, the pages of our website that you visit, the time and date of your visit, the time spent on those pages, and other statistics.

Cookies
Cookies are files with a small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your computer's hard drive. Like many websites, we use "cookies" to collect information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.

Security
The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.

Changes to This Privacy Policy
This Privacy Policy is effective as of [Date] and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted on this page.

Contact Us
If you have any questions about this Privacy Policy, please contact us.
`;
