import "../../App.css";
import { TERMS_OF_SERVICE } from "./Data";
import { Header, Segment, Form, TextArea } from "semantic-ui-react";

const TermsOfService = () => {
  document.title = "Satellax - Terms of Service";
  return (
    <Segment
      textAlign="center"
      style={{
        borderRadius: "12px",
        padding: "25px",
      }}
    >
      <Header as="h1">Terms of Service</Header>
      <Form style={{ paddingBottom: "10px" }}>
        <TextArea rows={30} value={TERMS_OF_SERVICE} />
      </Form>
    </Segment>
  );
};

export default TermsOfService;
