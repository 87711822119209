import { SET_ADD_DOCUMENT_MODAL_OPEN } from "../../store/Actions";
import Avvvatars from "avvvatars-react";
import { useDispatch } from "react-redux";
import { Segment, FormInput, Loader } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/reducers/RootReducer";

const AddDocumentBar = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);

  return (
    <>
      {user.data ? (
        <Segment style={{ borderRadius: "12px" }}>
          {user && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ marginRight: "10px" }}>
                <Avvvatars
                  value={user.data.firstName[0] + user.data.lastName[0]}
                  size={45}
                />
              </span>
              <div style={{ flex: "1", width: "100%" }}>
                <FormInput
                  readOnly
                  fluid
                  icon="linkify"
                  iconPosition="left"
                  placeholder="Add a Document (crtl+l)"
                  onClick={() => {
                    dispatch({
                      type: SET_ADD_DOCUMENT_MODAL_OPEN,
                      payload: true,
                    });
                  }}
                />
              </div>
            </div>
          )}
        </Segment>
      ) : (
        <Loader content="Loading Document Editor..." inline="centered" />
      )}
    </>
  );
};
export default AddDocumentBar;
