import { protectedApi } from "./BaseApi";

const ENDPOINT = "document";

export default class DocumentService {
  create(
    title: String,
    url: String,
    tags: string[],
    collectionIdentifier: String,
    onSuccess: any,
    onFailure: any,
  ) {
    protectedApi
      .post(ENDPOINT, { title, url, tags, collectionIdentifier })
      .then((response) => {
        if (response.data.header.status == "SUCCESS") {
          onSuccess(response.data.document);
        } else {
          onFailure(response.data.message);
        }
      })
      .catch((_error) => {
        onFailure("Failed to create Docuent.");
      });
  }

  createWithoutCollection(
    title: String,
    url: String,
    tags: string[],
    onSuccess: any,
    onFailure: any,
  ) {
    protectedApi
      .post(ENDPOINT, { title, url, tags })
      .then((response) => {
        if (response.data.header.status == "SUCCESS") {
          onSuccess(response.data.document);
        } else {
          onFailure(response.data.message);
        }
      })
      .catch((_error) => {
        onFailure("Failed to create Docuent.");
      });
  }

  retrieve(identifier: String, onSuccess: any, onFailure: any) {
    protectedApi
      .get(ENDPOINT + "/" + identifier)
      .then((response) => {
        if (response.data.header.status == "SUCCESS") {
          onSuccess(response.data.document);
        } else {
          onFailure(response.data.message);
        }
      })
      .catch((_error) => {
        onFailure("Failed to retrieve Docuent.");
      });
  }

  update(
    identifier: String,
    title: String,
    url: String,
    tags: string[],
    collectionIdentifier: String,
    onSuccess: any,
    onFailure: any,
  ) {
    protectedApi
      .put(ENDPOINT + "/" + identifier, {
        title,
        url,
        tags,
        collectionIdentifier,
      })
      .then((response) => {
        if (response.data.header.status == "SUCCESS") {
          onSuccess(response.data.document);
        } else {
          onFailure(response.data.message);
        }
      })
      .catch((_error) => {
        onFailure("Failed to update Docuent.");
      });
  }

  delete(identifier: String, onSuccess: any, onFailure: any) {
    protectedApi
      .delete(ENDPOINT + "/" + identifier)
      .then((response) => {
        if (response.data.header.status == "SUCCESS") {
          onSuccess(response.data.document);
        } else {
          onFailure(response.data.message);
        }
      })
      .catch((_error) => {
        onFailure("Failed to delete Docuent.");
      });
  }
}
