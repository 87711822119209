import { protectedApi } from "./BaseApi";

const ENDPOINT = "trending";

export default class TrendingService {
  retrieve(afterTimestamp: any, onSuccess: any, onFailure: any) {
    protectedApi
      .get(ENDPOINT, {
        params: {
          afterTimestamp: afterTimestamp,
        },
      })
      .then((response) => {
        if (response.data.header.status == "SUCCESS") {
          onSuccess(response.data.events);
        } else {
          onFailure(response.data.message);
        }
      })
      .catch((_error) => {
        onFailure("Unable to retrieve Trending Feed.");
      });
  }
}
