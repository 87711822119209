import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal, Input, Radio, Confirm } from "semantic-ui-react";
import CollectionService from "../../services/CollectionService";
import { success, error } from "../global/Popup";
import { useSelector } from "react-redux";
import { RootState } from "../../store/reducers/RootReducer";
import { SET_UPDATE_COLLECTION_MODAL_OPEN } from "../../store/Actions";
import { useDispatch } from "react-redux";

const UpdateCollection = (props: any) => {
  const dispatch = useDispatch();

  const open = useSelector(
    (state: RootState) => state.modals.updateCollectionModalOpen,
  );
  const collection = useSelector((state: RootState) => state.collection);

  const [title, setTitle] = useState(collection.data.title);
  const [description, setDescription] = useState(collection.data.description);
  const [privateCollection, setPrivateCollection] = useState(
    collection.data.private,
  );
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const navigate = useNavigate();

  const handleSuccessfulCollectionDeletion = (data: any) => {
    close();
    success("Collection deleted.");
    props.refreshCollections();
    navigate("/");
  };

  const handleCollectionDelete = () => {
    new CollectionService().delete(
      collection.data.identifier,
      handleSuccessfulCollectionDeletion,
      handleFailure,
    );
  };

  const handleSuccess = (data: any) => {
    success("Collection updated.");
    close();
  };

  const closeConfirmModal = () => {
    setConfirmDialogOpen(false);
  };

  const handleFailure = (message: string) => {
    error(message);
  };

  const handleTitleChange = (data: any) => {
    setTitle(data.value);
  };

  const handleDescriptionChange = (data: any) => {
    setDescription(data.value);
  };

  const handlePrivateToggleChange = () => {
    setPrivateCollection(!privateCollection);
  };

  const submit = () => {
    new CollectionService().update(
      collection.data.identifier,
      title,
      description,
      privateCollection,
      handleSuccess,
      handleFailure,
    );
    close();
  };

  const close = () => {
    setConfirmDialogOpen(false);
    dispatch({ type: SET_UPDATE_COLLECTION_MODAL_OPEN, payload: false });
  };

  return (
    <Modal
      size="small"
      open={open}
      onClose={() => close()}
      dimmer={{ inverted: true, style: { background: "rgba(0, 0, 0, 0.5)" } }}
      style={{ top: "7rem" }}
    >
      <Modal.Header>Update Collection</Modal.Header>

      <Modal.Content>
        <Input
          autoFocus
          style={{ width: "100%" }}
          value={title}
          placeholder="Title"
          onChange={(_, data) => handleTitleChange(data)}
        />
        <br />
        <br />
        <Input
          style={{ width: "100%" }}
          value={description}
          placeholder="Description"
          onChange={(_, data) => handleDescriptionChange(data)}
        />
        <br />
        <br />
        <Radio
          toggle
          checked={privateCollection}
          onChange={(_, __) => handlePrivateToggleChange()}
          label={privateCollection ? "Private Collection" : "Public Collection"}
        />
      </Modal.Content>

      <Modal.Actions>
        <Button
          style={{ float: "left" }}
          negative
          onClick={(_) => setConfirmDialogOpen(true)}
        >
          Delete
        </Button>
        <Button onClick={() => close()}>Cancel</Button>
        <Button primary onClick={() => submit()}>
          Update
        </Button>
      </Modal.Actions>

      <Confirm
        size="mini"
        open={confirmDialogOpen}
        dimmer={{
          inverted: true,
          style: { background: "rgba(0, 0, 0, 0.5)" },
        }}
        onCancel={closeConfirmModal}
        onConfirm={() => handleCollectionDelete()}
        header="Collection Delete"
        content={
          "Are you sure you want to delete your Collection '" + title + "'?"
        }
      />
    </Modal>
  );
};

export default UpdateCollection;
