import axios from 'axios';

const URL = process.env.REACT_APP_API_URL;

export const protectedApi = axios.create({
    baseURL: URL + '/api/v1/',
    withCredentials: true
});

export const authApi = axios.create({
    baseURL: URL + '/api/v1/auth',
});

protectedApi.defaults.headers.common['Content-Type'] = 'application/json';
authApi.defaults.headers.common['Content-Type'] = 'application/json';
