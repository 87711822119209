import "../../App.css";
import Avvvatars from "avvvatars-react";
import { useEffect, useState, useRef } from "react";
import moment from "moment";
import { XEmbed } from "react-social-media-embed";
import { SET_ADD_DOCUMENT_MODAL_OPEN } from "../../store/Actions";
import { useDispatch } from "react-redux";
import YouTube from "react-youtube";
import {
  Feed as SemanticFeed,
  Loader,
  Icon,
  Container,
  Label,
  Divider,
} from "semantic-ui-react";

import { success, error } from "../global/Popup";
import TrendingService from "../../services/TrendingService";
import DocumentService from "../../services/DocumentService";
import AddDocumentBar from "../global/AddDocumentBar";

const DOCUMENT_ADD_TYPE = "DOCUMENT_ADD";

const TrendingFeed = () => {
  const [hasMore, setHasMore] = useState(true);
  const bottomRef = useRef<HTMLDivElement>(null);
  const trendingService = new TrendingService();
  const [events, setEvents] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  document.title = "Satellax";

  const retrieveEvents = () => {
    if (loading === false) {
      setLoading(true);
      let afterTimestamp = Date.now();
      if (events.length > 0) {
        afterTimestamp = moment(events[events.length - 1].dateTime).valueOf();
      }

      trendingService.retrieve(
        afterTimestamp,
        handleEventsRetrievalSuccess,
        handleEventsRetrievalFailure,
      );
    }
  };

  useEffect(() => {
    retrieveEvents();
  }, []);

  const handleScroll = () => {
    if (
      bottomRef.current &&
      bottomRef.current.getBoundingClientRect().bottom <=
        window.innerHeight + window.scrollY
    ) {
      if (hasMore === true) {
        retrieveEvents();
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [events, loading, hasMore]);

  const closeCreateDocumentModal = () => {
    dispatch({ type: SET_ADD_DOCUMENT_MODAL_OPEN, payload: false });
  };

  const handleEventsRetrievalSuccess = (data: any) => {
    setEvents((prevEvents) => prevEvents.concat(data));
    setHasMore(data.length > 0);
    setLoading(false);
  };

  const handleEventsRetrievalFailure = (message: String) => {
    error(message);
    setLoading(false);
  };

  const renderEventContent = (event: any) => {
    var timestamp = moment
      .utc(event.dateTime)
      .local()
      .format("MMMM DD [at] hh:mm A");

    return (
      <div style={{ display: "flex", alignItems: "flex-start" }}>
        <div style={{ width: "45px" }}>
          <Avvvatars
            value={event.user.firstName[0] + event.user.lastName[0]}
            size={45}
          />
        </div>
        <div style={{ paddingLeft: "8px" }}>
          <a>{event.user.firstName + " " + event.user.lastName}</a> added{" "}
          <a target="_blank" href={event.document.url}>
            {event.document.title}
          </a>{" "}
          to their collection <a>{event.collection.title}.</a>
          <SemanticFeed.Date style={{ padding: "8px 0px" }}>
            {timestamp}
          </SemanticFeed.Date>
          <div style={{ textAlign: "left" }}>
            {event.document.tags.map((tag: any) => (
              <Label key={tag}>{tag}</Label>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const renderWidget = (url: string) => {
    const patterns: { [key: string]: RegExp } = {
      twitter: /twitter\.com\/.*\/status\/(\d+)/,
      youtube: /youtube\.com\/watch\?v=([a-zA-Z0-9_\-]+)/,
      pdf: /.*\.pdf/,
      instagram: /instagram\.com\/p\/([a-zA-Z0-9_\-]+)/,
    };

    const youtubeOpts = {
      height: "390",
      width: "100%",
      playerVars: {
        autoplay: 0 as 0,
      },
    };

    for (let type in patterns) {
      const match = url.match(patterns[type]);
      if (match) {
        switch (type) {
          case "twitter":
            return (
              <div
                style={{
                  margin: "0 auto",
                  maxWidth: "550px",
                }}
              >
                <XEmbed url={match[1]} />
              </div>
            );
          case "pdf":
            const pdfUrl = `${url}#toolbar=0`;
            return (
              <div style={{ textAlign: "center" }}>
                <embed
                  src={pdfUrl}
                  type="application/pdf"
                  width="100%"
                  height="400px"
                />
              </div>
            );
          case "youtube":
            return (
              <div style={{ textAlign: "center" }}>
                <YouTube videoId={match[1]} opts={youtubeOpts} />
              </div>
            );
          default:
            return null;
        }
      }
    }
    return null;
  };

  const handleDocumentAddedSuccessfully = (data: any) => {
    success("Document Added.");
    closeCreateDocumentModal();
  };

  const handleFailure = (message: String) => {
    error(message);
  };

  const addToDefaultCollection = (document: any) => {
    new DocumentService().createWithoutCollection(
      document.title,
      document.url,
      document.tags,
      handleDocumentAddedSuccessfully,
      handleFailure,
    );
  };

  return (
    <>
      <Container text textAlign="center" style={{ width: "100%" }}>
        <AddDocumentBar />
        <Divider />
        <SemanticFeed>
          {events.map((event: any, index: number) => {
            // if (event.type === HIGHLIGHT_TYPE){
            // return (
            //     <SemanticFeed.Event>
            //         <SemanticFeed.Label image={ event.userImageLink } />
            //         <SemanticFeed.Content>
            //             <SemanticFeed.Summary>
            //                 <a href={ event.userLink }>{ event.userName }</a> highlighed from <a target='_blank' href={ event.document.externalLink }>{ event.document.title }</a>
            //                 <br />
            //                 <SemanticFeed.Date>{ event.dateTime }</SemanticFeed.Date>
            //             </SemanticFeed.Summary>
            //             <SemanticFeed.Extra text style={{ maxWidth: 'none'}}>
            //                 { event.text }
            //             </SemanticFeed.Extra>
            //             <SemanticFeed.Meta>
            //                 <SemanticFeed.Like>
            //                     <Icon name='triangle up' /> { event.upvotes } upvotes
            //                 </SemanticFeed.Like>
            //                 <SemanticFeed.Like>
            //                     <Icon name='plus square' /> Add to Unread
            //                 </SemanticFeesd.Like>
            //             </SemanticFeed.Meta>
            //         </SemanticFeed.Content>
            //     </SemanticFeed.Event>
            // )
            // }
            if (event.type === DOCUMENT_ADD_TYPE) {
              return (
                <SemanticFeed.Event
                  key={`${event.identifier}-${index}`}
                  style={{
                    background: "#fff",
                    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  <SemanticFeed.Content style={{ padding: "8px" }}>
                    <SemanticFeed.Label
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        margin: 0,
                        fontSize: "1em",
                        fontWeight: "700",
                      }}
                    >
                      {renderEventContent(event)}
                    </SemanticFeed.Label>

                    <div style={{ padding: "12px 8px 0px 8px" }}>
                      <SemanticFeed.Summary style={{ textAlign: "center" }}>
                        {renderWidget(event.document.url)}
                      </SemanticFeed.Summary>

                      <SemanticFeed.Meta style={{ fontSize: "small" }}>
                        {/* <SemanticFeed.Like>
                            <Icon name="triangle up" /> {event.upvotes} upvotes
                          </SemanticFeed.Like> */}
                        <SemanticFeed.Like
                          onClick={() => addToDefaultCollection(event.document)}
                        >
                          <Icon name="plus square" /> Add to Read Later
                        </SemanticFeed.Like>
                      </SemanticFeed.Meta>
                    </div>
                  </SemanticFeed.Content>
                </SemanticFeed.Event>
              );
            }
            return <></>;
          })}
        </SemanticFeed>
        <Loader active={loading} content="Loading Feed" inline="centered" />
        {!hasMore && (
          <div style={{ paddingTop: "12px" }}>
            All caught up! <Icon name="rocket" />
          </div>
        )}
        <div ref={bottomRef} style={{ height: "1px", visibility: "hidden" }} />
      </Container>
    </>
  );
};

export default TrendingFeed;
