import { SET_COLLECTIONS } from "../Actions";

interface CollectionsState {
  data: any[];
}

const initialState: CollectionsState = {
  data: [],
};

const collectionsReducer = (
  state = initialState,
  action: any,
): CollectionsState => {
  switch (action.type) {
    case SET_COLLECTIONS:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default collectionsReducer;
