import "../../App.css";
import { useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import {
  Container,
  Input,
  Header,
  Button,
  Divider,
  Checkbox,
  Segment,
  Message,
  TextArea,
  Form,
  Grid,
  List,
} from "semantic-ui-react";
import { TERMS_OF_SERVICE } from "./Data";
import UserService from "../../services/UserService";
import { success, error } from "../global/Popup";

const Register = () => {
  const TOKEN_KEY = "token";
  const IDENTIFIER_KEY = "identifier";
  const [searchParams] = useSearchParams();

  const token = searchParams.get(TOKEN_KEY);
  const identifier = searchParams.get(IDENTIFIER_KEY);

  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [firstNameValid, setFirstNameValid] = useState(true);

  const [lastName, setLastName] = useState("");
  const [lastNameValid, setLastNameValid] = useState(true);

  const [password, setPassword] = useState("");
  const [passwordValid, setPasswordValid] = useState(true);

  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordValid, setConfirmPasswordValid] = useState(true);

  const [termsOfServiceChecked, setTermsOfServiceChecked] = useState(false);
  const [displayTermsOfServiceError, setDisplayTermsOfServiceError] =
    useState(false);

  document.title = "Satellax - Register";

  const handleSuccess = (token: string) => {
    success("Registration complete!");
    navigate("/");
  };

  const handleFailure = (data: any) => {
    error("Failed to login. Please ensure that your email and password match.");
  };

  // First Name
  const handleFirstNameChange = (data: string) => {
    setFirstName(data);
    setFirstNameValid(validateFirstName(data));
  };

  const validateFirstName = (input: string = "") => {
    if (input == "") {
      input = firstName;
    }
    var valid = true;

    if (input.length < 2) {
      valid = false;
    }

    const specialCharacters = "!@#$%^&*()_+-=[]{};:'\"\\|,.<>/?0123456789 ";
    for (let char of input) {
      if (specialCharacters.includes(char)) {
        valid = false;
      }
    }

    setFirstNameValid(valid);
    return valid;
  };

  // Last Name
  const handleLastNameChange = (data: string) => {
    setLastName(data);
    setLastNameValid(validateLastName(data));
  };

  const validateLastName = (input: string = "") => {
    if (input == "") {
      input = lastName;
    }

    var valid = true;

    if (input.length < 2) {
      valid = false;
    }

    const specialCharacters = "!@#$%^&*()_+-=[]{};:'\"\\|,.<>/?0123456789 ";
    for (let char of input) {
      if (specialCharacters.includes(char)) {
        valid = false;
      }
    }

    setLastNameValid(valid);
    return valid;
  };

  // Password
  const handlePasswordChange = (data: string) => {
    setPassword(data);
    validatePassword(data);
  };

  const handleConfirmPasswordChange = (data: string) => {
    setConfirmPassword(data);
    setConfirmPasswordValid(validateConfirmPassword(data));
  };

  const validatePassword = (input: string = "") => {
    if (input == "") {
      input = password;
    }
    var valid = true;
    if (input.length < 8) {
      valid = false;
    }

    let hasLowercase = false;
    let hasUppercase = false;
    for (let char of input) {
      if (char >= "a" && char <= "z") {
        hasLowercase = true;
      }
      if (char >= "A" && char <= "Z") {
        hasUppercase = true;
      }
    }
    if (!hasLowercase || !hasUppercase) {
      valid = false;
    }

    let hasDigit = false;
    for (let char of input) {
      if (char >= "0" && char <= "9") {
        hasDigit = true;
      }
    }
    if (!hasDigit) {
      valid = false;
    }

    const specialCharacters = "!@#$%^&*()_+-=[]{};:'\"\\|,.<>/?";
    let hasSpecialCharacter = false;
    for (let char of input) {
      if (specialCharacters.includes(char)) {
        hasSpecialCharacter = true;
      }
    }
    if (!hasSpecialCharacter) {
      valid = false;
    }

    setPasswordValid(valid);
    return valid;
  };

  const validateConfirmPassword = (input: string = "") => {
    if (input == "") {
      input = confirmPassword;
    }

    var valid = true;
    if (input == "") {
      valid = false;
    }

    if (input != password) {
      valid = false;
    }
    setConfirmPasswordValid(valid);
    return valid;
  };

  const validateToken = () => {
    if (token != null) return true;
    else return false;
  };

  const validateIdentifier = () => {
    if (identifier != null) return true;
    else return false;
  };

  const handleTermsOfServiceClick = (e: any) => {
    setTermsOfServiceChecked(!termsOfServiceChecked);
    checkTermsOfServiceError(!termsOfServiceChecked);
  };

  const checkTermsOfServiceError = (check: boolean) => {
    if (check == true) {
      setDisplayTermsOfServiceError(false);
    } else if (check == false) {
      setDisplayTermsOfServiceError(true);
    }
  };

  const handleTermsOfServiceLinkClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    window.open("/terms-of-service", "_blank");
  };

  const handleSubmitClick = () => {
    checkTermsOfServiceError(termsOfServiceChecked);
    const validity = [
      validateToken(),
      validateIdentifier(),
      validateFirstName(),
      validateLastName(),
      validatePassword(),
      validateConfirmPassword(),
      termsOfServiceChecked == true,
    ];

    if (validity.every((value) => value === true)) {
      new UserService().register(
        firstName,
        lastName,
        password,
        confirmPassword,
        token!!,
        identifier!!,
        termsOfServiceChecked,
        handleSuccess,
        handleFailure,
      );
    } else {
      error("All fields are required for registration.");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "97vh",
      }}
    >
      <Segment
        textAlign="center"
        style={{
          width: "100%",
          maxWidth: "800px",
          borderRadius: "12px",
          padding: "25px",
        }}
      >
        <Header as="h1">Registration</Header>

        <Divider />
        <Container textAlign="center"></Container>

        <Grid columns={2} stackable>
          <Grid.Column>
            <Input
              autoFocus
              error={!firstNameValid}
              style={{ width: "100%", margin: "5px 0" }}
              placeholder="First Name"
              onChange={(e) => handleFirstNameChange(e.target.value)}
            />
          </Grid.Column>

          <Grid.Column>
            <Input
              error={!lastNameValid}
              style={{ width: "100%", margin: "5px 0" }}
              placeholder="Last Name"
              onChange={(e) => handleLastNameChange(e.target.value)}
            />
          </Grid.Column>
        </Grid>

        <Input
          error={!passwordValid}
          style={{ width: "100%", margin: "5px 0" }}
          placeholder="Password"
          type="password"
          onChange={(e) => handlePasswordChange(e.target.value)}
        />

        <Input
          error={!confirmPasswordValid}
          style={{ width: "100%", margin: "5px 0" }}
          placeholder="Confirm Password"
          type="password"
          onChange={(e) => handleConfirmPasswordChange(e.target.value)}
        />

        <Segment placeholder>
          <Grid columns={2} relaxed="very" stackable>
            <Grid.Column>
              <Header as="h2" textAlign="center">
                Free Tier ($0 per month)
              </Header>
              <List>
                <List.Item>5 Additional Collections</List.Item>
                <List.Item>10 Documents per Collection</List.Item>
                <List.Item>5 Tags per Document</List.Item>
              </List>
            </Grid.Column>

            <Grid.Column style={{ opacity: 0.5 }}>
              <Header as="h2" textAlign="center">
                Premium Tier (Coming Soon)
              </Header>
              <List>
                <List.Item>Unlimited Additional Collections</List.Item>
                <List.Item>Unlimited Documents per Collection</List.Item>
                <List.Item>Unlimited Tags per Document</List.Item>
                <List.Item>AI Curated Articles</List.Item>
              </List>
            </Grid.Column>
          </Grid>

          <Divider vertical>Or</Divider>
        </Segment>

        {displayTermsOfServiceError ? (
          <Message negative>
            <Message.Header>
              The terms of service must be accepted
            </Message.Header>
          </Message>
        ) : (
          <></>
        )}

        <Form style={{ paddingBottom: "10px" }}>
          <TextArea readOnly rows={8} value={TERMS_OF_SERVICE} />
        </Form>

        <Checkbox
          checked={termsOfServiceChecked}
          onClick={handleTermsOfServiceClick}
          label={<label>I agree to the above Terms of Service.</label>}
        />
        <Divider />
        <Button size="large" primary onClick={handleSubmitClick}>
          Submit
        </Button>
      </Segment>
    </div>
  );
};

export default Register;
