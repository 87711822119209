import React, { useEffect, useState } from "react";
import {
  SET_ADD_DOCUMENT_MODAL_OPEN,
  SET_EDITING_DOCUMENT,
} from "../../store/Actions";
import { useDispatch } from "react-redux";
import {
  Modal,
  Button,
  FormInput,
  LabelGroup,
  FormField,
  Form,
  FormGroup,
  ButtonGroup,
  Dropdown,
} from "semantic-ui-react";
import LinkService from "../../services/LinkService";
import { success, warning, error } from "../global/Popup";
import { useSelector } from "react-redux";
import { RootState } from "../../store/reducers/RootReducer";
import DocumentService from "../../services/DocumentService";

interface DocumentEditorProps {
  refreshCollections: any;
  top?: boolean;
}

const DocumentEditor: React.FC<DocumentEditorProps> = ({
  top = true,
  ...props
}) => {
  const dispatch = useDispatch();
  const collections = useSelector((state: RootState) => state.collections);
  const selectedCollection = useSelector(
    (state: RootState) => state.collection,
  );
  const editingDocument = useSelector(
    (state: RootState) => state.document.editing,
  );
  const open = useSelector(
    (state: RootState) => state.modals.addDocumentModalOpen,
  );

  var selected = null;
  if (selectedCollection.data) {
    selected = selectedCollection.data;
  } else if (collections.data.length > 0) {
    selected = collections.data[0];
  }

  var initialTags = [];
  if (selected) {
    initialTags = selected.tags;
  }

  var style = {};
  if (top === true) {
    style = { top: "7rem" };
  }

  var initiadHeading = "Add Document";
  if (editingDocument.data) {
    initiadHeading = editingDocument.data.title;
  }

  const [url, setUrl] = useState(String);
  const [heading, setHeading] = useState(initiadHeading);
  const [title, setTitle] = useState(String);
  const [tags, setTags] = useState<string[]>([]);
  const [tagOptions, setTagOptions] = useState(initialTags);
  const [identifier, setIdentifier] = useState(String);
  const [titleLoading, setTitleLoading] = useState(false);
  const [documentSelectedCollection, setDocumentSelectedCollection] =
    useState(selected);

  useEffect(() => {
    var document = editingDocument.data;
    if (document !== null) {
      var selected = null;
      if (selectedCollection.data) {
        selected = selectedCollection.data;
      } else if (collections.data.length > 0) {
        selected = collections.data[0];
      }
      setUrl(document.url || "");
      setTags(document.tags);
      setTitle(document.title);
      setHeading(document.title || "Add Document");
      setIdentifier(document.identifier || "");
      setDocumentSelectedCollection(selected);
    } else {
      setTagOptions([]);

      var selected = null;
      if (selectedCollection.data) {
        selected = selectedCollection.data;
        setTagOptions(selected.tags);
      } else if (collections.data.length > 0) {
        selected = collections.data[0];
        setTagOptions(selected.tags);
      }
      setUrl("");
      setTitle("");
      setHeading("Add Document");
      setIdentifier("");
      setDocumentSelectedCollection(selected);
    }
  }, [editingDocument, selectedCollection, collections]);

  const deleteDocument = (identifier: any) => {
    new DocumentService().delete(
      identifier,
      handleSuccessfulDocumentDeletion,
      handleFailure,
    );
  };

  const handleSuccessfulDocumentDeletion = (data: any) => {
    success("Document deleted.");
    closeModal();
    props.refreshCollections();
  };

  const saveDocument = (
    identifier: string,
    title: string,
    url: string,
    tags: string[],
    collectionIdentifier: string,
  ) => {
    new DocumentService().update(
      identifier,
      title,
      url,
      tags,
      collectionIdentifier,
      handleDocumentUpdatedSuccessfully,
      handleFailure,
    );
  };

  const createDocument = (
    title: string,
    url: string,
    tags: string[],
    collectionIdentifier: string,
  ) => {
    new DocumentService().create(
      title,
      url,
      tags,
      collectionIdentifier,
      handleDocumentAddedSuccessfully,
      handleFailure,
    );
  };

  const closeModal = () => {
    dispatch({ type: SET_ADD_DOCUMENT_MODAL_OPEN, payload: false });
    dispatch({ type: SET_EDITING_DOCUMENT, payload: null });
    setTags([]);
  };

  const handleDocumentAddedSuccessfully = (_: any) => {
    success("New Document added.");
    closeModal();
    props.refreshCollections();
  };

  const handleDocumentUpdatedSuccessfully = () => {
    success("Document updated.");
    closeModal();
    props.refreshCollections();
  };

  const handleFailure = (message: String) => {
    error(message);
  };

  const handleTagDelete = (_: any, data: any) => {
    setTags(data.value);
  };

  const handleAddButtonClick = () => {
    const valid = validateDocument();
    if (valid === true) {
      createDocument(title, url, tags, documentSelectedCollection.identifier);
    }
  };

  const handleSaveButtonClick = () => {
    const valid = validateDocument();
    if (valid === true) {
      saveDocument(
        identifier,
        title,
        url,
        tags,
        documentSelectedCollection.identifier,
      );
    }
  };

  const handleDocumentDeleteClicked = (event: any, identifier: any) => {
    event.preventDefault();
    deleteDocument(identifier);
  };

  const validateTag = (value: string): boolean => {
    if (/^[a-z0-9 ]+$/.test(value)) {
      if (!tags.includes(value)) {
        return true;
      } else {
        warning("Tag already exists.");
      }
    } else {
      warning("Invalid tag. Only lowercase letters and numbers are allowed.");
    }
    return false;
  };

  const validateDocument = () => {
    if (title === "" || url === "") {
      warning("Ensure the Title and URL fields are correctly entered.");
    } else if (!isValidURL(url)) {
      warning("Please enter a valid URL.");
    } else if (tags.length === 0) {
      warning("Enter atleast one tag.");
    } else {
      return true;
    }
    return false;
  };

  const isValidURL = (input: string) => {
    const pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i",
    ); // fragment locator

    return pattern.test(input);
  };

  const handleTagAddition = (_: any, data: any) => {
    var incomingTag = data.value.trim();
    var validTag = validateTag(incomingTag);
    if (validTag === true) {
      const tagsCopy = [...tags];
      tagsCopy.push(incomingTag);
      setTags(tagsCopy);

      const combinedTags = Array.from(new Set([...tagsCopy, ...tagOptions]));
      setTagOptions(combinedTags);
    }
  };

  const handleTitleRetrievalSuccess = (title: string) => {
    setTitle(title);
    setTitleLoading(false);
  };

  const handleTitleRetrievalFailure = () => {
    setTitleLoading(false);
  };

  const handleCollectionSelectionChange = (_: any, data: any) => {
    const foundCollection = collections.data.find(
      (collection: any) => collection.identifier === data.value,
    );
    setDocumentSelectedCollection(foundCollection || null);

    const combinedTags = Array.from(
      new Set([...tags, ...foundCollection.tags]),
    );
    setTagOptions(combinedTags);
  };

  const retrieveTitle = () => {
    if (isValidURL(url)) {
      setTitleLoading(true);
      new LinkService().retrieve(
        url,
        handleTitleRetrievalSuccess,
        handleTitleRetrievalFailure,
      );
    } else {
      warning("Please enter a valid URL.");
    }
  };

  const openUrl = (url: string) => {
    window.open(url);
  };

  return (
    <Modal
      size="small"
      dimmer={{ inverted: true, style: { background: "rgba(0, 0, 0, 0.5)" } }}
      open={open}
      onClose={closeModal}
      closeOnDimmerClick={true}
      closeOnEscape={true}
      style={style}
    >
      {title ? (
        <Modal.Header>{title}</Modal.Header>
      ) : (
        <Modal.Header>Add Document</Modal.Header>
      )}
      <Modal.Content>
        <LabelGroup size="small">
          <Form size={"small"} key={"small"} style={{ paddingTop: "8px" }}>
            <FormGroup widths="equal">
              <FormField>
                <FormInput
                  icon="linkify"
                  iconPosition="left"
                  placeholder="mysite.com"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  autoFocus
                />
              </FormField>

              <ButtonGroup
                style={{ width: "128px" }}
                icon
                labeled
                labelposition="right"
                size="small"
              >
                <Button
                  size="small"
                  content="Fill"
                  icon="magic"
                  onClick={() => retrieveTitle()}
                />
              </ButtonGroup>
            </FormGroup>

            <FormGroup widths="equal">
              <FormField>
                <FormInput
                  icon="heading"
                  iconPosition="left"
                  placeholder={titleLoading ? "Retrieving Title..." : "Title"}
                  value={title}
                  loading={titleLoading}
                  disabled={titleLoading}
                  onChange={(_, data) => setTitle(data.value)}
                />
              </FormField>
            </FormGroup>

            {documentSelectedCollection ? (
              <FormGroup widths="equal">
                <FormField>
                  <Dropdown
                    placeholder="Collection"
                    fluid
                    search
                    selection
                    value={documentSelectedCollection.identifier}
                    onChange={handleCollectionSelectionChange}
                    options={collections.data.map(
                      (collection: { identifier: any; title: any }) => ({
                        key: collection.identifier,
                        text: collection.title,
                        value: collection.identifier,
                      }),
                    )}
                  />
                </FormField>
              </FormGroup>
            ) : (
              <div>loading...</div>
            )}

            <FormGroup widths="equal">
              <FormField>
                <Dropdown
                  options={tagOptions.map((tag: any) => ({
                    key: tag,
                    text: tag,
                    value: tag,
                  }))}
                  placeholder="Tags"
                  search
                  selection
                  fluid
                  multiple
                  allowAdditions
                  value={tags}
                  onAddItem={handleTagAddition}
                  onChange={handleTagDelete}
                />
              </FormField>

              {editingDocument.data == null ? (
                <ButtonGroup
                  style={{ width: "128px" }}
                  icon
                  labeled
                  labelposition="right"
                  size="small"
                >
                  <Button
                    primary
                    size="small"
                    content="Add"
                    icon="add"
                    onClick={() => handleAddButtonClick()}
                  />
                </ButtonGroup>
              ) : (
                <ButtonGroup icon size="small">
                  <Button
                    primary
                    size="small"
                    icon="save"
                    onClick={() => handleSaveButtonClick()}
                  />
                  <Button
                    size="small"
                    icon="external alternate"
                    onClick={() => openUrl(url)}
                  />
                  <Button
                    negative
                    size="small"
                    icon="trash"
                    onClick={(event) =>
                      handleDocumentDeleteClicked(event, identifier)
                    }
                  />
                </ButtonGroup>
              )}
            </FormGroup>
          </Form>
        </LabelGroup>
      </Modal.Content>
    </Modal>
  );
};

export default DocumentEditor;
