import { SET_COLLECTION } from "../Actions";

interface CollectionState {
  data: any;
}

const initialState: CollectionState = {
  data: null,
};

const collectionReducer = (
  state = initialState,
  action: any,
): CollectionState => {
  switch (action.type) {
    case SET_COLLECTION:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default collectionReducer;
