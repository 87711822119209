import React, { useState } from "react";
import { Button, Modal, Checkbox, Form } from "semantic-ui-react";
import UserService from "../../services/UserService";

interface CookieSettingsProps {
  open: boolean;
  closable: boolean;
  onClose: () => void;
}

const CookieSettings: React.FC<CookieSettingsProps> = (props) => {
  var settings = new UserService().retrieveSelectedCookieSettings();
  if (settings == null) {
    settings = {
      marketing: false,
      analytics: false,
    };
  }
  const [essentialCookies] = useState(true);
  const [analyticsCookies, setAnalyticsCookies] = useState(settings.analytics);
  const [marketingCookies, setMarketingCookies] = useState(settings.marketing);

  const saveCookieSettings = () => {
    new UserService().setSelectedCookieSettings({
      analytics: analyticsCookies,
      marketing: marketingCookies,
    });
    props.onClose();
  };

  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      closeOnDimmerClick={props.closable}
      closeOnEscape={props.closable}
      dimmer={{ inverted: true, style: { background: "rgba(0, 0, 0, 0.5)" } }}
    >
      <Modal.Header>Cookie Preferences</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <Checkbox
              label="Essential - Necessary for the website to function (always active)."
              checked={essentialCookies}
              readOnly
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              label="Analytics - Help us understand how visitors interact with the website."
              checked={analyticsCookies}
              onChange={() => setAnalyticsCookies(!analyticsCookies)}
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              label="Marketing - Used to track visitors across websites. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third party advertisers."
              checked={marketingCookies}
              onChange={() => setMarketingCookies(!marketingCookies)}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button disabled={!props.closable} onClick={props.onClose}>
          Cancel
        </Button>
        <Button primary onClick={saveCookieSettings}>
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default CookieSettings;
