import {
  SET_ADD_DOCUMENT_MODAL_OPEN,
  SET_UPDATE_COLLECTION_MODAL_OPEN,
} from "../Actions";

interface ModalState {
  addDocumentModalOpen: boolean;
  updateCollectionModalOpen: boolean;
}

const initialState: ModalState = {
  addDocumentModalOpen: false,
  updateCollectionModalOpen: false,
};

const modalReducer = (state = initialState, action: any): ModalState => {
  switch (action.type) {
    case SET_ADD_DOCUMENT_MODAL_OPEN:
      return {
        ...state,
        addDocumentModalOpen: action.payload,
      };
    case SET_UPDATE_COLLECTION_MODAL_OPEN:
      return {
        ...state,
        updateCollectionModalOpen: action.payload,
      };
    default:
      return state;
  }
};

export default modalReducer;
