import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import {
  SET_EDITING_DOCUMENT,
  SET_ADD_DOCUMENT_MODAL_OPEN,
  SET_UPDATE_COLLECTION_MODAL_OPEN,
  SET_COLLECTION,
} from "../../store/Actions";
import { useDispatch } from "react-redux";
import {
  Container,
  Header,
  List,
  Segment,
  Button,
  Loader,
  Transition,
  Popup,
  Divider,
  Icon,
  ButtonContent,
} from "semantic-ui-react";
import UpdateCollection from "../modals/UpdateCollection";
import AddDocumentBar from "../global/AddDocumentBar";
import { useSelector } from "react-redux";
import { RootState } from "../../store/reducers/RootReducer";
import CollectionService from "../../services/CollectionService";
import { error } from "../global/Popup";

const Collection = ({ refreshCollections }: { refreshCollections: any }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const collection = useSelector((state: RootState) => state.collection);

  const { identifier } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const animationTimeInMilliseconds = 500;
  const animationStyle = "fade";
  const tag = queryParams.get("tag") || "";

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
    if (
      (loading === false && collection.data === null) ||
      (loading === false && collection.data.identifier !== identifier)
    ) {
      setLoading(true);
      new CollectionService().retrieve(
        identifier as string,
        handleCollectionRetrievalSuccess,
        handleCollectionRetrievalFailure,
      );
    }
  });

  const handleCollectionRetrievalSuccess = (collection: any) => {
    setLoading(false);
    dispatch({ type: SET_COLLECTION, payload: collection });
    document.title = "Satellax - " + collection.title;
  };

  const handleCollectionRetrievalFailure = (message: string) => {
    error(message);
  };

  const openDocumentEditor = (document: any) => {
    dispatch({ type: SET_EDITING_DOCUMENT, payload: document });
    dispatch({ type: SET_ADD_DOCUMENT_MODAL_OPEN, payload: true });
  };

  const handleUpdateCollectionClick = (_: any) => {
    openUpdateCollectionModal();
  };

  const openUpdateCollectionModal = () => {
    dispatch({ type: SET_UPDATE_COLLECTION_MODAL_OPEN, payload: true });
  };

  const openUrl = (url: string) => {
    window.open(url, "_blank");
  };

  const filteredDocuments = collection.data
    ? tag !== ""
      ? collection.data.documents.filter((it: any) => it.tags.includes(tag))
      : collection.data.documents
    : [];

  if (loading === true || collection.data === null) {
    return (
      <Container text textAlign="center">
        <Loader active={true} content="Loading" inline="centered" />
      </Container>
    );
  }

  return (
    <Container text textAlign="center">
      <Segment style={{ borderRadius: "12px" }}>
        <div style={{ padding: "10px 10px 0px 10px" }}>
          <Header>{collection.data.title}</Header>
          <div>{collection.data.description}</div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: "12px",
            }}
          >
            <Button.Group size="small">
              {collection.data.private ? (
                <Popup
                  trigger={<Button icon="eye slash" />}
                  position="bottom center"
                  mouseEnterDelay={300}
                  mouseLeaveDelay={300}
                  on="hover"
                  wide
                >
                  This Collection is <b>private</b>. All Documents added{" "}
                  <b>will not</b> be seen by others on Satellax.
                </Popup>
              ) : (
                <>
                  <Popup
                    trigger={<Button icon="eye" />}
                    position="bottom center"
                    mouseEnterDelay={300}
                    mouseLeaveDelay={300}
                    on="hover"
                    wide
                  >
                    This Collection is public. All Documents added <b>will</b>{" "}
                    be seen by others on Satellax.
                  </Popup>
                  <Button primary animated="vertical" onClick={() => {}}>
                    <ButtonContent visible>
                      <Icon name="share alternate" />
                    </ButtonContent>
                    <ButtonContent hidden>share</ButtonContent>
                  </Button>
                </>
              )}
              {collection.data.protected ? (
                <Popup
                  trigger={<Button icon="lock" />}
                  position="bottom center"
                  mouseEnterDelay={300}
                  mouseLeaveDelay={300}
                  on="hover"
                  wide
                >
                  This Collection is <b>protected</b>. It <b>can not</b> be
                  edited or deleted.
                </Popup>
              ) : (
                <>
                  <Button
                    animated="vertical"
                    onClick={handleUpdateCollectionClick}
                  >
                    <ButtonContent visible>
                      <Icon name="edit" />
                    </ButtonContent>
                    <ButtonContent hidden>Edit</ButtonContent>
                  </Button>
                </>
              )}
            </Button.Group>
          </div>
        </div>
      </Segment>

      <AddDocumentBar />
      <Divider />

      <Transition
        duration={animationTimeInMilliseconds}
        animation={animationStyle}
        visible={true}
      >
        {filteredDocuments.length > 0 ? (
          <Segment style={{ borderRadius: "12px" }}>
            <List
              divided
              relaxed
              style={{ textAlign: "left", borderRadius: "0px !important" }}
            >
              {filteredDocuments.map((it: any) => (
                <List.Item
                  key={it.identifier}
                  style={{
                    textAlign: "left",
                    borderRadius: "0px !important",
                  }}
                >
                  <List.Content style={{ padding: "15px" }}>
                    <Button.Group
                      floated="right"
                      size="small"
                      labelposition="right"
                    >
                      <Button
                        animated="vertical"
                        primary
                        onClick={() => openUrl(it.url)}
                      >
                        <ButtonContent visible>
                          <Icon name="external alternate" />
                        </ButtonContent>
                        <ButtonContent hidden>Open</ButtonContent>
                      </Button>

                      <Button
                        animated="vertical"
                        onClick={() => openDocumentEditor(it)}
                      >
                        <ButtonContent visible>
                          <Icon name="edit" />
                        </ButtonContent>
                        <ButtonContent hidden>Edit</ButtonContent>
                      </Button>
                    </Button.Group>

                    <List.Header
                      style={{
                        lineHeight: "33px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {it.title}
                    </List.Header>
                  </List.Content>
                </List.Item>
              ))}
            </List>
          </Segment>
        ) : (
          <div style={{ paddingTop: "12px" }}>
            This Collection has no Documents <Icon name="frown outline" />
          </div>
        )}
      </Transition>

      <UpdateCollection refreshCollections={refreshCollections} />
    </Container>
  );
};

export default Collection;
