import "../../App.css";
import { useState } from "react";
import moment from "moment";
import {
  Feed as SemanticFeed,
  Segment,
  Loader,
  FeedExtra,
  Image,
  Transition,
  Icon,
  Container,
  Label,
} from "semantic-ui-react";

const News = () => {
  const [loading, setLoading] = useState(false);
  const animationTimeInMilliseconds = 500;
  const animationStyle = "fade";
  document.title = "Satellax - News";

  var events = [
    {
      identifier: "18",
      title: "UI Improvement",
      description: "Added Footer to side menus and login screens.",
      timestamp: "14-05-2024 20h37",
    },
    {
      identifier: "17",
      title: "UI Improvement",
      description: "Added selected Collection tags to dropdown.",
      timestamp: "07-05-2024 22h36",
    },
    {
      identifier: "16",
      title: "Feature Announcement",
      description: "Added 'Add to Read Later' on Feeds.",
      timestamp: "07-05-2024 21h41",
    },
    {
      identifier: "15",
      title: "UI Improvement",
      description: "Added infinite scroll on home feed.",
      timestamp: "05-05-2024 14h56",
    },
    {
      identifier: "14",
      title: "UI Improvement",
      description: "Added profile menu dropdown.",
      timestamp: "28-04-2024 12h26",
    },
    {
      identifier: "13",
      title: "UX Announcement",
      description: "Added performance, analytics and marketing consent.",
      timestamp: "28-04-2024 12h26",
    },
    {
      identifier: "12",
      title: "UI Improvement",
      description: "Implemented more robust Tag selection dropdown.",
      timestamp: "22-04-2024 19h36",
    },
    {
      identifier: "11",
      title: "Feature Annoucement",
      description: "Trending Feed content widgets added for Youtube and X.",
      timestamp: "21-04-2024 12h40",
    },
    {
      identifier: "10",
      title: "Feature Annoucement",
      description: "reCAPTCHA implemented on sign-up.",
      timestamp: "16-04-2024 17h52",
    },
    {
      identifier: "9",
      title: "Platform Annoucement",
      description: "Satellax is now available in South Africa.",
      timestamp: "13-04-2024 17h52",
    },
    {
      identifier: "8",
      title: "Feature Addition",
      description: "Documents can now be moved between collections.",
      timestamp: "28-02-2024 21h05",
    },
    {
      identifier: "7",
      title: "Platform Annoucement",
      description: "Satellax is now in Closed Beta testing.",
      timestamp: "26-02-2024 12h00",
    },
    {
      identifier: "6",
      title: "Application Enhancements",
      description: "Added Collection deletion confirmation dialogs.",
      timestamp: "31-01-2024 21h17",
    },
    {
      identifier: "5",
      title: "Application Enhancements",
      description:
        "Various side-menu data refresh issues on Collection data changes has been addressed. A 'private' icon has been added to the side-menu for Collections set to 'private'.",
      timestamp: "31-01-2024 20h15",
    },
    {
      identifier: "3",
      title: "Application Feature",
      description:
        "Public and Private collection functionality has been added. All Collections marked as 'private' will not have any activity or Documents shown to other users on Satellax. The default visibility for all new Collections is 'private'.",
      timestamp: "29-01-2024 21h35",
    },
    {
      identifier: "2",
      title: "Application Enhancements",
      description:
        "Protected Collections are explicitly shown and can not be altered, or deleted.",
      timestamp: "29-01-2024 20h12",
    },
    {
      identifier: "1",
      title: "Application Enhancements",
      description:
        "An 'unsaved' Tag that is still present in the input field will be considered for creating and updating Documents.\n\nImprovements have been made to the side menu to automatically refresh Collections upon creating, updating or deleting a Document.",
      timestamp: "24-01-2024 20h35",
    },
  ];

  return (
    <>
      <Container text textAlign="center">
        <Loader active={loading} content="Loading" inline="centered" />

        <Transition
          duration={animationTimeInMilliseconds}
          animation={animationStyle}
          visible={!loading}
        >
          <SemanticFeed>
            {events.map((event: any) => {
              const formattedDate = moment(
                event.timestamp,
                "DD/MM/YYYY HH[h]mm",
              ).format();
              var timestamp = moment(formattedDate).fromNow();

              return (
                <SemanticFeed.Event
                  key={event.identifier}
                  style={{
                    background: "#fff",
                    boxShadow: "0 1px 2px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  <SemanticFeed.Content
                    style={{
                      textAlign: "center",
                      padding: "5px 10px 10px 10px",
                    }}
                  >
                    <SemanticFeed.Summary>
                      {event.title}
                      <SemanticFeed.Date>{timestamp}</SemanticFeed.Date>
                    </SemanticFeed.Summary>
                    <FeedExtra
                      style={{ maxWidth: "none" }}
                      text
                      content={event.description}
                    />
                  </SemanticFeed.Content>
                </SemanticFeed.Event>
              );
            })}
          </SemanticFeed>
        </Transition>
      </Container>
    </>
  );
};

export default News;
