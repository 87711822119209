import { SET_USER } from "../Actions";

interface UserState {
  data: {
    firstName: string;
    lastName: string;
  } | null;
}

const initialState: UserState = {
  data: null,
};

const userReducer = (state = initialState, action: any): UserState => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
