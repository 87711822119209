import "../../App.css";
import { Header, Segment, Container, Button } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";

const About = ({ loggedIn }: { loggedIn: Boolean }) => {
  document.title = "Satellax - About";

  const navigate = useNavigate();
  const handleSignUp = () => {
    navigate("/sign-up");
  };

  return (
    <Segment
      textAlign="center"
      style={{
        borderRadius: "12px",
        padding: "25px",
      }}
    >
      <Container text>
        <Header as="h1">Welcome to Satellax</Header>
        <p>
          Our mission is to bridge the gaps in knowledge across various
          disciplines and industries. At Satellax, we believe that the right
          connection and the right information can transform careers, enrich
          personal development, and deepen hobbies into profound passions.
        </p>
        <Header as="h2">Our Vision</Header>
        <p>
          In a world brimming with information, finding relevant and reliable
          knowledge can be daunting. Satellax was founded on the principle that
          everyone — whether professionals in their field, enthusiasts, or
          lifelong learners—should have access to information that enlightens,
          educates, and empowers. We strive to cut through the noise of the vast
          information landscape to bring you clarity and actionable knowledge.
        </p>

        <Header as="h2">What We Do</Header>
        <p>
          Satellax is more than just a platform; it's a dynamic community of
          like-minded individuals committed to growth and excellence. We connect
          people not only with each other but with high-quality information.
          Whether you're looking to advance in your career, explore a personal
          interest deeper, or even start a new hobby, Satellax is your gateway
          to achieving those goals.
        </p>

        <Header as="h2">Our Commitment</Header>
        <p>
          At Satellax, we are committed to providing a platform that not only
          facilitates the sharing of knowledge but also fosters meaningful
          connections. We are constantly evolving, using feedback from our
          community to improve and expand our offerings. Our goal is to be your
          trusted partner in your journey of personal and professional
          development.
        </p>

        {!loggedIn && (
          <>
            <p>
              Ready to dive deeper into your career, personal development, or
              hobbies? Join Satellax today and start connecting with a world of
              possibilities.
            </p>
            <Button primary onClick={handleSignUp}>
              Join Us
            </Button>
          </>
        )}
      </Container>
    </Segment>
  );
};

export default About;
