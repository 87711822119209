import "./App.css";
import { Grid, List, Segment, Header, Container } from "semantic-ui-react";
import { GlobalHotKeys } from "react-hotkeys";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { useState, useEffect, createRef } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  SET_USER,
  SET_COLLECTIONS,
  SET_ADD_DOCUMENT_MODAL_OPEN,
  SET_COLLECTION_ADD_TEXT_FOCUS,
  SET_UPDATE_COLLECTION_MODAL_OPEN,
} from "./store/Actions";

import News from "./components/views/News";
import Menu from "./components/global/Menu";
import Login from "./components/views/Login";
import Signup from "./components/views/Signup";
import { error } from "./components/global/Popup";
import Trending from "./components/views/Trending";
import Register from "./components/views/Register";
import SideMenu from "./components/global/SideMenu";
import Collection from "./components/views/Collection";
import TermsOfService from "./components/views/TermsOfService";
import PrivacyPolicy from "./components/views/PrivacyPolicy";
import About from "./components/views/About";
import UserService from "./services/UserService";
import CollectionService from "./services/CollectionService";
import CookieSettings from "./components/modals/CookieSettings";
import CanonicalUrl from "./components/canonical/CanonicalUrl";
import DocumentEditor from "./components/modals/DocumentEditor";

const keyMap = {
  OPEN_ADD_DOCUMENT_MODAL: ["ctrl+l"],
  FOCUS_ADD_COLLECTION_SIDE_MENU_FIELD: ["ctrl+k"],
};

const App = () => {
  const contextRef = createRef<HTMLDivElement>();
  const navigate = useNavigate();
  var isLoggedIn = new UserService().isLoggedIn();
  const [cookieSettingsOpen, setCookieSettingsOpen] = useState(false);
  const [cookieSettingsClosable, setCookieSettingsClosable] = useState(false);
  const dispatch = useDispatch();

  const handleUserRetrieveSuccess = (data: any) => {
    dispatch({ type: SET_USER, payload: data });
  };

  const handleUserRetrieveFailure = (_: string) => {};

  const openAddDocumentModal = () => {
    dispatch({ type: SET_ADD_DOCUMENT_MODAL_OPEN, payload: true });
  };

  const focusAddCollectionSideMenuField = () => {
    dispatch({ type: SET_COLLECTION_ADD_TEXT_FOCUS, payload: true });
  };

  const handlers = {
    OPEN_ADD_DOCUMENT_MODAL: openAddDocumentModal,
    FOCUS_ADD_COLLECTION_SIDE_MENU_FIELD: focusAddCollectionSideMenuField,
  };

  useEffect(() => {
    const storedOptions = new UserService().retrieveSelectedCookieSettings();
    if (storedOptions) {
      setCookieSettingsClosable(true);
    } else {
      setCookieSettingsClosable(false);
      setCookieSettingsOpen(true);
    }
  }, []);

  useEffect(() => {
    isLoggedIn = new UserService().isLoggedIn();
    if (isLoggedIn === true) {
      retrieveCollections();
      new UserService().retrieve(
        handleUserRetrieveSuccess,
        handleUserRetrieveFailure,
      );
    }
  }, [isLoggedIn]);

  const ProtectedRoute = ({ children }: { children: any }) => {
    useEffect(() => {
      isLoggedIn = new UserService().isLoggedIn();

      if (isLoggedIn === false) {
        navigate("/login", { replace: true });
      }
    }, []);

    if (!isLoggedIn) {
      return null;
    }

    return <>{children}</>;
  };

  const handleContactUs = () => {
    const recipient = "hello@satellax.com";
    const subject = "Hello!";

    const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(subject)}`;
    window.location.href = mailtoLink;
  };

  const handleLogout = () => {
    new UserService().logOut();
    dispatch({ type: SET_USER, payload: null });
    dispatch({ type: SET_COLLECTIONS, payload: null });
    dispatch({ type: SET_ADD_DOCUMENT_MODAL_OPEN, payload: false });
    dispatch({ type: SET_UPDATE_COLLECTION_MODAL_OPEN, payload: false });
    navigate("/login", { replace: true });
  };

  const handleCloseCookieSettings = () => {
    setCookieSettingsOpen(false);
  };

  const openCookieSettings = () => {
    setCookieSettingsOpen(true);
  };

  const retrieveCollections = () => {
    new CollectionService().retrieveAll(
      handleCollectionsRetrievalSuccess,
      handleCollectionRetrievalFailure,
    );
  };

  const handleCollectionsRetrievalSuccess = (data: any) => {
    dispatch({ type: SET_COLLECTIONS, payload: data });
  };

  const handleCollectionRetrievalFailure = (message: String) => {
    error(message);
  };

  if (isLoggedIn === false) {
    return (
      <div>
        <Menu onCookiePreferencesClick={() => {}} onLogoutClick={() => {}} />
        <Grid stackable>
          <Grid.Column width={4}></Grid.Column>
          <Grid.Column width={8}>
            <div style={{ padding: "6rem 2rem" }}>
              <CanonicalUrl />
              <Routes>
                <Route path="*" element={<Login />} />
                <Route path="/about" element={<About loggedIn={false} />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/terms-of-service" element={<TermsOfService />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/sign-up" element={<Signup />} />
              </Routes>
            </div>
          </Grid.Column>
          <Grid.Column width={4}></Grid.Column>
          <CookieSettings
            open={cookieSettingsOpen}
            closable={cookieSettingsClosable}
            onClose={handleCloseCookieSettings}
          />
          <ToastContainer />
        </Grid>

        <Segment
          vertical
          style={{
            background: "#fff",
            padding: "2em 0em",
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
          }}
        >
          <Container>
            <Grid columns={3} divided stackable>
              <Grid.Row>
                <Grid.Column>
                  <List link>
                    <Header as="h4">Platform</Header>
                    <List.Item as="a" href="/about">
                      About
                    </List.Item>
                    <List.Item as="a" onClick={handleContactUs}>
                      Contact Us
                    </List.Item>
                  </List>
                </Grid.Column>
                <Grid.Column>
                  <Header as="h4">Terms and Conditions</Header>
                  <List link>
                    <List.Item as="a" href="/privacy-policy">
                      Privacy Policy
                    </List.Item>
                    <List.Item as="a" href="/terms-of-service">
                      Terms of Service
                    </List.Item>
                  </List>
                </Grid.Column>
                <Grid.Column>
                  <Header as="h4">Satellax</Header>
                  <p>A product by Satellax PTY.</p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </Segment>
      </div>
    );
  }

  return (
    <GlobalHotKeys keyMap={keyMap} handlers={handlers}>
      <Menu
        onCookiePreferencesClick={openCookieSettings}
        onLogoutClick={handleLogout}
      />

      <div style={{ padding: "6rem 2rem" }} ref={contextRef}>
        <Grid container stackable>
          <Grid.Column width={3}>
            <div
              style={{
                position: "sticky",
                top: 84,
                float: "right",
                width: 300,
              }}
            >
              <SideMenu refreshCollections={retrieveCollections} />
            </div>
          </Grid.Column>

          <Grid.Column width={10}>
            <div style={{ height: "auto", maxWidth: "100%" }}>
              <Routes>
                <Route
                  path="/"
                  element={
                    <ProtectedRoute>
                      <Trending />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/collection/:identifier"
                  element={
                    <ProtectedRoute>
                      <Collection refreshCollections={retrieveCollections} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/news"
                  element={
                    <ProtectedRoute>
                      <News />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="*"
                  element={
                    <ProtectedRoute>
                      <Trending />
                    </ProtectedRoute>
                  }
                />
                <Route path="/about" element={<About loggedIn={true} />} />
                <Route path="/terms-of-service" element={<TermsOfService />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              </Routes>
            </div>
          </Grid.Column>

          <Grid.Column floated="left" width={3}></Grid.Column>
        </Grid>
      </div>

      <DocumentEditor refreshCollections={retrieveCollections} />

      <CookieSettings
        open={cookieSettingsOpen}
        closable={cookieSettingsClosable}
        onClose={handleCloseCookieSettings}
      />

      <ToastContainer />
    </GlobalHotKeys>
  );
};

export default App;
