import "../../App.css";
import { Link } from "react-router-dom";
import Avvvatars from "avvvatars-react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/reducers/RootReducer";

import {
  Image,
  DropdownMenu,
  Menu as SementicMenu,
  Dropdown,
  DropdownItem,
} from "semantic-ui-react";

const Menu = ({
  onLogoutClick,
  onCookiePreferencesClick,
}: {
  onLogoutClick: any;
  onCookiePreferencesClick: any;
}) => {
  const user = useSelector((state: RootState) => state.user);

  if (user.data) {
    const trigger = (
      <span style={{ display: "flex", alignItems: "center" }}>
        <span style={{ marginRight: "10px" }}>
          <Avvvatars
            value={user.data.firstName[0] + user.data.lastName[0]}
            size={45}
          />
        </span>
        {user.data.firstName}
      </span>
    );

    return (
      <SementicMenu fixed="top" size="large" style={{ padding: 0 }}>
        <SementicMenu.Item
          style={{ border: "None", fontSize: "large", padding: 0 }}
          name="home"
          as={Link}
          to="/"
        >
          <Image
            src="/logo.png"
            size="small"
            style={{ width: "126px", padding: "2px", margin: "0px 14px" }}
            centered
          />
        </SementicMenu.Item>

        <SementicMenu.Menu position="right">
          <Dropdown
            item
            style={{ padding: "7px 20px" }}
            icon={null}
            trigger={trigger}
          >
            <DropdownMenu>
              <Dropdown.Header
                content={
                  "Logged in as: " +
                  user.data.firstName +
                  " " +
                  user.data.lastName
                }
              />

              <DropdownItem
                icon="options"
                text="Cookie Preferences"
                name="cookie-settings"
                onClick={onCookiePreferencesClick}
              />
              <DropdownItem
                icon="log out"
                text="Logout"
                name="logout"
                onClick={onLogoutClick}
              />
            </DropdownMenu>
          </Dropdown>
        </SementicMenu.Menu>
      </SementicMenu>
    );
  } else {
    return (
      <SementicMenu fixed="top" size="large" style={{ padding: 0 }}>
        <SementicMenu.Item
          style={{ border: "None", fontSize: "large", padding: "13px 0" }}
          name="home"
          as={Link}
          to="/"
        >
          <Image
            src="/logo.png"
            size="small"
            style={{ width: "126px", padding: "2px", margin: "0px 14px" }}
            centered
          />
        </SementicMenu.Item>
      </SementicMenu>
    );
  }
};

export default Menu;
