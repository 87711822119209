import "./index.css";
import "semantic-ui-css/semantic.min.css";
import { Provider } from "react-redux";
import Store from "./store/Store";
import App from "./App";
import ReactDOM from "react-dom/client";

import { BrowserRouter as Router } from "react-router-dom";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <Provider store={Store}>
    <Router>
      <App />
    </Router>
    ,
  </Provider>,
);
