import { protectedApi } from './BaseApi'

const ENDPOINT = 'link'

export default class LinkService {
    retrieve(url: String, onSuccess: any, onFailure: any) {
        protectedApi
            .post(ENDPOINT, { url })
            .then(response => {
                if (response.data.header.status == "SUCCESS") {
                    onSuccess(response.data.title);
                } else {
                    onFailure(response.data.message);
                }
            })
            .catch(_error => {
                onFailure("Failed to retrieve title.")
            });
    }
}
