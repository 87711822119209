import { SET_COLLECTION_ADD_TEXT_FOCUS } from "../Actions";

interface FocusReducer {
  collectionAddSideMenuFocus: boolean;
}

const initialState: FocusReducer = {
  collectionAddSideMenuFocus: false,
};

const focusReducer = (state = initialState, action: any): FocusReducer => {
  switch (action.type) {
    case SET_COLLECTION_ADD_TEXT_FOCUS:
      return {
        ...state,
        collectionAddSideMenuFocus: action.payload,
      };
    default:
      return state;
  }
};

export default focusReducer;
