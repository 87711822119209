import "../../App.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import {
  Image,
  Input,
  Header,
  Button,
  Divider,
  Segment,
} from "semantic-ui-react";

import UserService from "../../services/UserService";
import { success, error } from "../global/Popup";

const Signup = () => {
  const [email, setEmail] = useState("");
  const [requestAccessVisible, setRequestAccessVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailValid, setEmailValid] = useState(true);
  const navigate = useNavigate();
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>();
  const SITEKEY = process.env.REACT_APP_RECAPTCHA_SITEKEY || "";

  (window as any).recaptchaOptions = {
    useRecaptchaNet: true,
    enterprise: true,
  };

  const handleSuccess = () => {
    success("You have successfully signed up. Expect an email shortly.");
    setLoading(false);
    navigate("/login");
  };

  document.title = "Satellax - Signup";

  const handleRequestAccess = () => {
    const recipient = "hello@satellax.com";
    const subject = "Platform Access Request";

    const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(subject)}`;
    window.location.href = mailtoLink;
  };

  const handleFailure = (data: any) => {
    error(
      "Sign up failed. Please request access if you haven't been granted it.",
    );
    setRequestAccessVisible(true);
    setLoading(false);
  };

  const isEmailValid = (email: string): boolean => {
    // Regular expression for validating email address
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const handleEmailAddressChange = (_: any, data: any) => {
    setEmail(data.value);
  };

  const handleLoginClick = () => {
    var validEmailAddress = isEmailValid(email);

    if (validEmailAddress === false) {
      error("Please enter a valid email address.");
      setEmailValid(false);
    } else if (recaptchaToken === undefined || recaptchaToken === null) {
      error("Please confirm that you are not a robot.");
    } else {
      setLoading(true);
      new UserService().signup(
        email,
        recaptchaToken,
        handleSuccess,
        handleFailure,
      );
    }
  };

  const handleRecaptchaChange = (token: string | null) => {
    setRecaptchaToken(token);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "70vh",
      }}
    >
      <Segment
        textAlign="center"
        style={{
          width: "100%",
          maxWidth: "800px",
          borderRadius: "12px",
          padding: "25px",
        }}
      >
        <Image fluid src="/logo.png" size="medium" centered />
        <Divider />

        <Input
          id="username"
          autoFocus
          error={!emailValid}
          disabled={loading}
          loading={loading}
          style={{ width: "100%", margin: "5px 0" }}
          placeholder="Email Address"
          onChange={handleEmailAddressChange}
        />

        <ReCAPTCHA
          style={{
            padding: "5px 0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          sitekey={SITEKEY}
          onChange={handleRecaptchaChange}
        />

        <Divider />

        {requestAccessVisible ? (
          <Button size="large" primary onClick={handleRequestAccess}>
            Request Access
          </Button>
        ) : (
          <Button
            size="large"
            loading={loading}
            primary
            onClick={handleLoginClick}
          >
            Sign-up
          </Button>
        )}
      </Segment>
    </div>
  );
};

export default Signup;
