import "../../App.css";
import { useState } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

import {
  Image,
  Input,
  Container,
  Button,
  Divider,
  Segment,
} from "semantic-ui-react";

import UserService from "../../services/UserService";
import { error } from "../global/Popup";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSuccess = (token: string) => {
    Cookies.set("token", token, {
      expires: 1,
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
    });
    navigate("/");
  };

  document.title = "Satellax - Login";

  const handleRequestAccess = () => {
    navigate("/sign-up");
  };

  const handleFailure = (data: any) => {
    error("Failed to login. Please ensure that your email and password match.");
  };

  const handleEmailAddressChange = (_: any, data: any) => {
    setEmail(data.value);
  };

  const handlePasswordChange = (_: any, data: any) => {
    setPassword(data.value);
  };

  const handleLoginClick = () => {
    new UserService().login(email, password, handleSuccess, handleFailure);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "15vh 0",
      }}
    >
      <Segment
        textAlign="center"
        style={{
          width: "100%",
          maxWidth: "800px",
          borderRadius: "12px",
          padding: "25px",
        }}
      >
        <Image fluid src="/logo.png" size="medium" centered />
        <Divider />
        <Input
          id="username"
          autoFocus
          style={{ width: "100%", margin: "5px 0" }}
          placeholder="Email Address"
          onChange={handleEmailAddressChange}
        />
        <Input
          style={{ width: "100%", margin: "5px 0" }}
          placeholder="Password"
          type="password"
          onChange={handlePasswordChange}
        />
        <Divider />
        <Button size="large" onClick={handleRequestAccess}>
          Sign-up
        </Button>
        <Button size="large" primary onClick={handleLoginClick}>
          Login
        </Button>
      </Segment>
    </div>
  );
};

export default Login;
