import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const CanonicalUrl = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const location = useLocation();
  const canonicalUrl = `${baseUrl}${location.pathname}`;

  return (
    <Helmet>
      <link rel="canonical" href={canonicalUrl} />
    </Helmet>
  );
};

export default CanonicalUrl;
