import { SET_EDITING_DOCUMENT } from "../Actions";

interface DocumentState {
  editing: {
    data: any | null;
  };
}

const initialState: DocumentState = {
  editing: { data: null },
};

const documentReducer = (state = initialState, action: any): DocumentState => {
  switch (action.type) {
    case SET_EDITING_DOCUMENT:
      return {
        ...state,
        editing: { data: action.payload },
      };
    default:
      return state;
  }
};

export default documentReducer;
